import {AxiosError, AxiosRequestConfig} from "axios";

import useApi from "./useApi";
import {Parcel} from "../../../functions/src/model/domain/Trackit";


interface UseUserParcelsResult  {
    call: () => void
    result?: Parcel[],
    loading: boolean,
    error?: AxiosError
}

const getParcelRequest = (): AxiosRequestConfig => ({
    method: "GET",
    url: `/api/parcels/`
})

export const useUserParcels = (onResult?: (result: Parcel[]) => void, onError?: (e: AxiosError) => void): UseUserParcelsResult => {
    const {call, result, loading, error} = useApi<Parcel[]>(onResult, onError)

    return {
        call: () => call(getParcelRequest()),
        result,
        loading,
        error
    }
}