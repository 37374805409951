
import {CourierCode} from "../../functions/src/model/domain/Trackingmore";


interface CourierResourceUtils {
    //trackingNumberMatcher: RegExp
    linkTemplate: (num: string) => string
}

const UPSTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => `https://www.ups.com/track?loc=en_us&tracknum=${num}&requester=ST/trackdetails`,
    }
}

const USPSTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${num}`,
    }
}

const FedExTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => `https://www.fedex.com/fedextrack/?tracknumbers=${num}`,
    }
}

const DHLTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => `https://www.dhl.com/en/express/tracking.html?AWB=${num}&brand=DHL`,
    }
}

const OnTracTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => `https://www.ontrac.com/trackingresults.asp?tracking_number=${num}`,
    }
}

const GLSTrackingResources = () : CourierResourceUtils  => {
    return {
        linkTemplate: (num: string) => String(num),
    }
}


export const trackingResources: Map<CourierCode, CourierResourceUtils> = new Map([
    ["ups", UPSTrackingResources()],
    ["usps", USPSTrackingResources()],
    ["fedex", FedExTrackingResources()],
    ["dhl", DHLTrackingResources()],
    ["ontrac", OnTracTrackingResources()],
    ["gls", GLSTrackingResources()],
])


