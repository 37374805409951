import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";
import {Parcel} from "../../../functions/src/model/domain/Trackit";

interface UseAddParcelResult {
    call: (parcel: Parcel) => void,
    result?: Parcel,
    loading?: boolean,
    error?: AxiosError
}



const addParcelRequest = (parcel: Parcel): AxiosRequestConfig => ({
    method: 'POST',
    url: '/api/parcels/add',
    data: parcel
})

export const useAddParcel = (onResult?: (result: Parcel) => void, onError?: (e: AxiosError) => void): UseAddParcelResult => {
    const {call, result, loading, error} = useApi<Parcel>(onResult, onError)

    return {
        call: (parcel: Parcel) => call(addParcelRequest(parcel)),
        result,
        loading,
        error
    }
}