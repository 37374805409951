import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {useState} from "react";

interface ApiResult<T> {
    call: (config: AxiosRequestConfig) => void
    result: T | undefined
    loading: boolean
    error: AxiosError | undefined
}

export default function useApi<T>(
    onResult?: (result: T) => void,
    onError?: (error: AxiosError) => void): ApiResult<T> {

    const [result, setResult] = useState<T>()
    const [error, setError] = useState<AxiosError>()
    const [loading, setLoading] = useState(false)

    const call = (config: AxiosRequestConfig) => {
        setLoading(true)
        setResult(undefined)
        setError(undefined)
        axios(config).then(r => {
            setLoading(false)
            setResult(r.data)
            onResult?.call(null, r.data)
        }).catch(e => {
            setLoading(false)
            setError(e)
            onError?.call(null, e)
        })
    }

    return {
        call,
        result,
        loading,
        error
    }
}
