import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";


interface UseCleanUpParcelsResult{
    call: () => void
    result?: string,
    loading: boolean,
    error?: AxiosError
}

const cleanUpRequest = (): AxiosRequestConfig => ({
  method: "POST",
  url: '/api/parcels/cleanup'
})



export default function useCleanUpParcels(): UseCleanUpParcelsResult {
    const {call, result, loading, error} = useApi<string>()
    return {
        call: () => call(cleanUpRequest()),
        result,
        loading,
        error
    }
}