import {CourierCode} from "../../functions/src/model/domain/Trackingmore";

export const courierColors = new Map<CourierCode, string>([
    ["ups", "#5a3d24"],
    ["usps", "#303060"],
    ["fedex", "#4d148c"],
    ["dhl", "#f0c000"],
    ["ontrac", "#d0d000"],
    ["gls", "#FCBF00"],
])


