import React from 'react'
import {createIcon} from '@chakra-ui/icons'

export const MenuIcon = createIcon({
    displayName: "MenuIcon",
    viewBox: "0 0 96 96",
    path: [
        <circle key={0} cx="48" cy="81" r="10" fill="currentColor"/>,
        <circle key={1} cx="48" cy="48" r="10" fill="currentColor"/>,
        <circle key={2} cx="48" cy="15" r="10" fill="currentColor"/>,
    ]
})