import React, {useEffect, useState} from "react";
import logo from '../../../assets/svg/trackit_logo.svg'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {
    IconButton,
    Center,
    Flex,
    Image,
    useColorMode,
    Heading,
    useColorModeValue,
    createIcon,
    Button,
    Circle,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text
} from "@chakra-ui/react";
import {SunIcon, MoonIcon} from '@chakra-ui/icons'
import {ReactComponent as Hamburger} from "../../../assets/svg/hamburger.svg";
import useIsDesktop from "../../../hooks/helpers/useIsDesktop";
import firebase from 'firebase'
import {UserContext} from "../../../App";

const HamburgerIcon = createIcon({
    displayName: "HamburgerIcon",
    viewBox: "0 0 106 105",
    path: <Hamburger/>
});


export interface MenuItem {
    name: string
    link: string
}

export interface ItemProps {
    name: string
    link: string
    selected: boolean
}


const Item = (props: ItemProps) => {
    const selectedBg = useColorModeValue("gray.700", "purple.300")
    //const hoverColor = useColorModeValue("rgba(0,0,0, .33)", "rgba(255,255,255, .25)")
    return (
        <Center w={"auto"}
                minWidth={"100px"}
                h={"full"}
                as={RouterLink}
                to={props.link}
                fontSize={"lg"}
                padding={4}
                color={"white"}
                fontWeight={"semibold"}
                bg={props.selected ? selectedBg : "transparent"}
                transition={'background 200ms ease-in-out'}
                _hover={{background: props.selected ? selectedBg : "rgba(255,255,255, .25)"}}
        >
            {props.name}
        </Center>
    )
}

interface HeaderProps {
    items: MenuItem[]
    showMenuCallback: () => void
    loginCallback: () => void
    logoutCallback: () => void
}


interface ResponsiveHeaderProps extends HeaderProps {
    headingColor: string,
    headingBg: string,
    user?: firebase.User | null
}

//Header for Desktops
const DesktopHeader = (props: ResponsiveHeaderProps): JSX.Element => {
    const [selected, setSelected] = useState("/dashboard")
    const location = useLocation()
    const modeIcon = useColorModeValue(<MoonIcon/>, <SunIcon/>)
    const {toggleColorMode} = useColorMode()

    useEffect(() => {
        setSelected(location.pathname !== "/" ? location.pathname : "/dashboard")
    }, [location])

    return (

        <Flex w={"full"} h={"64px"} align={"center"} bg={props.headingBg}>
            <Image w={"48px"} h={"48px"} mx={"24px"} src={logo} alt={"trackit logo"}/>
            <Heading size={"lg"} color={props.headingColor} my={0} mr={"24px"}>Trackit</Heading>
            <Flex height={"100%"} grow={1} align={"center"} justify={"start"}>
                {props.items.map((mi: MenuItem) =>
                    <Item
                        key={`menu-item-${mi.name}`}
                        selected={selected === mi.link}
                        {...mi}/>
                )}
            </Flex>
            <IconButton aria-label="themeIcon" icon={modeIcon} onClick={toggleColorMode} mr={4} variant={"ghost"}/>
            {props.user ?
                <Menu >
                    <MenuButton as={Circle} mx={4} w={"42px"} h={"42px"} justifyContent={"center"}
                                alignItems={"center"} bg={"blue.500"} cursor={"pointer"}>
                        {props.user.photoURL ?
                            <Image src={props.user.photoURL} />
                            :
                            <Text textAlign={"center"}>{abbreviate(props.user.displayName)}</Text>
                        }
                    </MenuButton>
                    <MenuList>
                        <MenuItem>Settings</MenuItem>
                        <MenuItem color={'red.400'} onClick={props.logoutCallback}>Log out</MenuItem>
                    </MenuList>
                </Menu> :
                <Button onClick={props.loginCallback} mx={4} variant={"text"}>Login</Button>}
        </Flex>
    )
}

//Header for mobile devices
const MobileHeader = (props: ResponsiveHeaderProps): JSX.Element => {
    return (
        <Flex w={"full"} h={"64px"} align={"center"} bg={props.headingBg}>
            <Heading position={"absolute"} w={"full"} color={props.headingColor} textAlign={"center"}>Trackit</Heading>
            <IconButton
                ml={2}
                aria-label={"menu-toggle"}
                icon={<HamburgerIcon/>}
                onClick={props.showMenuCallback}
                variant={"ghost"}
            />
            <Image w={"48px"} h={"48px"} mx={2} src={logo} alt={"trackit logo"}/>
            <Flex align={"center"} justify={"flex-end"} grow={1}>
                {!props.user && <Button onClick={props.loginCallback} mr={4} variant={'text'}>Login</Button>}
            </Flex>
        </Flex>
    )
}

export default function TrackitHeader(props: HeaderProps): JSX.Element {
    const isDesktop = useIsDesktop()
    const headingColor = useColorModeValue("white", "purple.300")
    const headingBg = useColorModeValue("green.500", "gray.700")

    return (
        <UserContext.Consumer>
            {(user?: firebase.User | null) => {
                if (isDesktop) {
                    return <DesktopHeader {...props} headingBg={headingBg} headingColor={headingColor} user={user}/>
                } else {
                    return <MobileHeader {...props} headingBg={headingBg} headingColor={headingColor} user={user}/>
                }
            }}
        </UserContext.Consumer>
    )


}

const abbreviate = (name: string | null): string => {
    if(!name) return ""
    const chunks = name.split(" ")
    if(chunks.length === 0) return ""
    return `${chunks[0][0]}${chunks[1][0]}`
}