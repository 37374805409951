import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";
import {Parcel} from "../../../functions/src/model/domain/Trackit";

interface UseRenameParcelResult {
    call: (number: string, label: string) => void,
    result?: Parcel,
    loading: boolean,
    error?: AxiosError
}

const useRenameRequest = (number: string, label: string): AxiosRequestConfig => ({
    method: "POST",
    url: `api/parcels/rename/${number}`,
    data: { label }
})

export default function useRenameParcel(onResult?: (r: Parcel) => void, onError?: (e: AxiosError) => void): UseRenameParcelResult {
    const {call, result, loading, error} = useApi<Parcel>(onResult, onError)

    return {
        call: (number: string, label: string) => call(useRenameRequest(number, label)),
        result,
        loading,
        error
    }

}