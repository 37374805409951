import {UseToastOptions} from "@chakra-ui/react";

const baseToast: UseToastOptions = {
    isClosable: true,
}

export const deleteParcelSuccessToast: UseToastOptions = {
    title: "Deleted Parcel",
    status: "success",
    ...baseToast,
}


export const deleteParcelFailToast: UseToastOptions = {
    title: "Unable to Delete Parcel",
    status: "error",
    ...baseToast
}

export const renameParcelSuccessToast: UseToastOptions = {
    title: "Renamed Parcel",
    status: "success",
    ...baseToast
}

export const renameParcelFailToast: UseToastOptions  = {
    title: "Failed to rename parcel",
    status: "warning",
    ...baseToast
}