import React from 'react'
import {TrackInfo} from "../../../model/domain/Trackingmore";
import {Box, Divider, Flex, Stack, Text, TextProps, useColorModeValue} from "@chakra-ui/react";
import dayjs from "dayjs";
import parsePlugin from 'dayjs/plugin/customParseFormat'
dayjs.extend(parsePlugin)

export interface TrackingInfoProps {
    info?: TrackInfo[]
    maxHeight?: number
}

const TIText = (props: TextProps) => {
    return <Text {...props}  fontSize={"sm"} mx={1}>{props.children}</Text>
}

export default function TrackingInfo(props: TrackingInfoProps): JSX.Element {
    const hoverColor = useColorModeValue("rgba(0,0,0, .1)", "rgba(255,255,255, .1)")
    const accentColor = useColorModeValue("green.500", "purple.300")
    if (!props.info) return <Text textAlign={"center"}>Ugh! No details yet :(</Text>
    return (
        <Flex direction="column" maxHeight={props.maxHeight || "unset"} overflowY={'hidden'}>
            <Flex direction={"row"} w={"full"} justify={"space-between"} align={'center'} mb={2} grow={1}>
                <TIText w={'25%'} color={accentColor} fontWeight={500}>Date & Time</TIText>
                <TIText w={'25%'} color={accentColor} fontWeight={500}>Location</TIText>
                <TIText w={'50%'} color={accentColor} fontWeight={500}>Status</TIText>
            </Flex>
            <Box overflowY={"auto"}>
                {props.info.map((info: TrackInfo, idx: number) => {
                    const date = dayjs(info.checkpoint_date, 'YYYY-MM-DD HH:mm').format("M/D YYYY, h:mm A")
                    return (
                        <Stack direction={"column"} key={`info-${idx}`} pb={2} _hover={{background: hoverColor}}
                            transition={"background 200ms ease-in-out"}>
                            <Divider/>
                            <Flex direction={"row"} w={"full"} justify={"space-between"} align={'center'}>
                                <TIText w={'25%'}>{date}</TIText>
                                <TIText w={'25%'}>{info.location}</TIText>
                                <TIText w={'50%'}>{info.tracking_detail}</TIText>
                            </Flex>
                        </Stack>
                    )
                })}
            </Box>
        </Flex>
    )
}