import {TMResponse, TrackingmoreCourier} from "../../../functions/src/model/domain/Trackingmore";
import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";


export interface UseGetCouriersResult {
    call: () => void
    result?: TMResponse<TrackingmoreCourier[]>
    loading: boolean
    error?: AxiosError
}

const config = (): AxiosRequestConfig => ({
    method: "GET",
    //url: "/api/tracking/couriers"
    url: "/api/mock/couriers"
})

export function useGetCouriers(onResult?: (r: TMResponse<TrackingmoreCourier[]>) => void, onError?: (e: AxiosError) => void): UseGetCouriersResult {
    const {call, loading, result, error} = useApi<TMResponse<TrackingmoreCourier[]>>(onResult, onError)

    return {
        call: () => call(config()),
        loading,
        result,
        error
    }
}