import firebase from "firebase/app";
import firebaseui from "firebaseui";

interface AuthResult {
    signIn: (firebaseUi: firebaseui.auth.AuthUI) => void,
}

export default function useAuth(
    onSuccess: (result: firebase.User, redirect: string) => boolean,
    onFailure: (error: unknown) => void): AuthResult {

    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: onSuccess,
            signInFailure: onFailure
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '/#/',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    const signIn = (firebaseUi: firebaseui.auth.AuthUI) => {
        firebaseUi.start("#auth-container", uiConfig)
    }


    return {
        signIn
    }
}