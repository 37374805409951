import * as React from 'react'
import { Button, Input, InputGroup, InputRightElement, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {useCallback, useState} from "react";
import {UserContext} from "../../../../App";

interface TrackingInputProps {
    onAdd: (number: string) => void
    addedNumbers: string[]
    //user: firebase.User | null
    loginCallback: () => void
}

export default function TrackingInput(props: TrackingInputProps): JSX.Element {
    const [number, setNumber] = useState("")
    const accentColor = useColorModeValue("green.500", "purple.300")

    const submit = useCallback((authenticated: boolean) => {
        if(!authenticated) {
            props.loginCallback()
        }else if (number) {
            props.onAdd(number)
            setNumber("")
        }
    }, [number, setNumber, props.loginCallback])

    const invalid = props.addedNumbers.includes(number)

    return (
        <UserContext.Consumer>
            { user =>
                <Stack
                    mt={"3rem"}
                    w={{base: "100%", sm: "66%", md: "50%", lg: "50%", xl: "33%"}}
                    px={{base: 4, sm: 0, md: 0}}>

                    <InputGroup size={"lg"}>
                        <Input
                            placeholder={"Paste a tracking number"}
                            focusBorderColor={accentColor}
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                            isInvalid={invalid}
                            size={"lg"}
                        />
                        <InputRightElement w={"4.5rem"}>
                            <Button
                                bg={accentColor}
                                onClick={() => submit(Boolean(user))}
                                disabled={!Boolean(number) || invalid}
                                px={"1rem"}
                                size={"lg"}
                                borderColor={!Boolean(number) ? "transparent" : "green.500"}
                            >
                                Trackit!
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Text color={"red.500"} w={"full"} textAlign={"center"} opacity={invalid ? 1 : 0} transition={"300ms ease-in-out"}>
                        Tracking number already exists
                    </Text>
                </Stack>
            }
        </UserContext.Consumer>

    )
}
