import * as React from 'react'
import tumbleweed_day from '../../../../assets/svg/tumbleweed_day.svg'
import tumbleweed_night from '../../../../assets/svg/tumbleweed_night.svg'
import desert_day from '../../../../assets/svg/desert_day.svg'
import desert_night from '../../../../assets/svg/desert_night.svg'
import './tumbleweed.css';
import {Box, Fade, Flex, Heading, Image, Text, useColorModeValue} from "@chakra-ui/react";


function NoParcelsText(): JSX.Element {
    const textColor = "#818181"
    return (
        <Flex direction={"column"} align={"center"} w={"full"} position={"absolute"}
              top={{base: "10%", md: "15%"}}>
            <Heading textAlign={"center"} mb={2} size={"md"} color={textColor}>
                {"...it\'s lonely around here"}
            </Heading>
            <Text textAlign={"center"} fontWeight={200} color={textColor}>
                Add stuff using the box above
            </Text>
        </Flex>
    )
}

interface NoParcelProps {
    showing: boolean
}

export default function NoParcels(props: NoParcelProps): JSX.Element {
    const desert = useColorModeValue(desert_day, desert_night)
    const tumbleweed = useColorModeValue(tumbleweed_day, tumbleweed_night)

    return (
        <Fade in={props.showing} unmountOnExit>
            <Flex w="full" h="full" position={"absolute"} justify={"center"} left={0} top={0}>
                <Flex w={{base: "100%", lg: "100%", xl: "70%"}} h={{base: "75%", lg: "100%"}}
                      position={"relative"} justify={"center"}
                      align={"center"}>

                    <NoParcelsText/>
                    <Image src={desert} w={"full"} grow={1}/>
                    <Box position={"absolute"} w={"full"}>
                        <Image id="tumbleweed" src={tumbleweed} w={"12vw"} h={"12vw"}/>
                    </Box>
                </Flex>
            </Flex>
        </Fade>
    )
}