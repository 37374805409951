import React from "react";
import ParcelListItem from "./ParcelListItem";
import {Fade, List} from "@chakra-ui/react";
import {Parcel} from "../../../../../functions/src/model/domain/Trackit";

interface ParcelListProps {
    parcels: Parcel[],
    onParcelDelete: (parcel: Parcel) => void
}

export const ParcelList = (props: ParcelListProps): JSX.Element => {
    return (
        <List w={{base: "100%", sm: "75%", md: "66%", lg: "50%", xl: "50%", "2xl": "33%"}}>
            <Fade in={Boolean(props.parcels)}>
                {/*<Box my={4} pl={2}>*/}
                {/*    <Heading as={"h4"} size={"lg"}>Tracked deliveries</Heading>*/}
                {/*</Box>*/}
                {props.parcels.map((item: Parcel, idx: number) =>
                    <ParcelListItem
                        key={`${item.courier_code}_${idx}`}
                        parcel={item}
                        onDeleteParcel={props.onParcelDelete}
                        divider={idx < (props.parcels.length - 1)}/>)}
            </Fade>
        </List>

    )
}
