import React from 'react'
import { TrackingmoreCourier} from "../../../../../functions/src/model/domain/Trackingmore";
import {ListItem, Image, Text, Divider, Flex, Stack} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";


interface CourierListItemProps {
    courier: TrackingmoreCourier
    onClick: () => void
    selected: boolean
}

export default function CourierListItem(props: CourierListItemProps): JSX.Element {

    return (
        <ListItem p={2} cursor={"pointer"} onClick={props.onClick}>
            <Stack spacing={3}>
                <Flex direction={"row"} align={"center"} wrap={"nowrap"}>
                    <Image h={"32px"} w={"32px"} borderRadius={"8px"} src={props.courier.courier_logo}/>
                    <Text fontSize={"12px"} mx={4} w={"full"} userSelect={"none"}>{props.courier.courier_name}</Text>
                    <CheckCircleIcon color={"green.500"} w={"24px"} h={"24px"} visibility={props.selected ? "inherit" : "hidden"}/>
                </Flex>
                <Divider w={"full"}/>
            </Stack>

        </ListItem>
    )
}