import * as React from 'react'
import {
    Center,
    Flex, FormControl, FormLabel,
    IconButton,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Skeleton,
    Stack, useBreakpointValue,
    useToast,
    UseToastOptions
} from "@chakra-ui/react";
import CourierAvatar from "../../../common/atoms/CourierAvatar";
import {useEffect, useState} from "react";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import {useDetectCourier, UseDetectCourierResult} from "../../../../hooks/api/useDetectCourier";
import {CourierCode, TMResponse, TrackingmoreCourier} from "../../../../../functions/src/model/domain/Trackingmore";
import {useAddParcel} from "../../../../hooks/api/useAddParcel";
import {AxiosError} from "axios";
import {Parcel} from "../../../../../functions/src/model/domain/Trackit";
import TrackingLink from "../../../common/atoms/TrackingLink";
import useIsDesktop from "../../../../hooks/helpers/useIsDesktop";

interface OnboardParcelItemProps {
    parcel: Parcel
    initialName: string
    deleteCallback: (number: string) => void
    refreshParcelsCallback: () => void
    onSelectCourier: (parcel: Parcel) => void
}



export default function OnboardParcelItem(props: OnboardParcelItemProps): JSX.Element {
    const toast = useToast()
    const isDesktop = useIsDesktop()
    const size: "mobile" | "desktop" | undefined = useBreakpointValue({base: "mobile", lg: "desktop"})
    const [name, setName] = useState(props.parcel.label || props.initialName)
    const [courierResult, setCourierResult] = useState<CourierCode>()
    const [showPopover, setShowPopover] = useState(false)

    //Detect courier callbacks and hook
    const detectCourierFailure = (e: AxiosError) => {
        console.log("failed to detect courier: ", e.message)
        toast(detectCourierFailureToast)
        setShowPopover(true)
    }

    const detectCourierSuccess = (r: TMResponse<TrackingmoreCourier[]>) => {
        if (r.data && r.data.length > 0) setCourierResult(r.data[0].courier_code)
    }

    const detectCourier = useDetectCourier(detectCourierSuccess, detectCourierFailure)

    useEffect(() => {
        if (props.parcel?.tracking_number && !props.parcel.courier_code) {
            console.log("detecting courier")
            detectCourier.call(props.parcel.tracking_number)
        }
    }, [props.parcel.tracking_number, props.parcel.courier_code])

    //Add parcel callbacks and hook
    const onParcelAddFailure = (e: AxiosError) => {
        console.warn(e)
        toast(parcelAddFailureToast)
    }

    const onParcelAddSuccess = (result: Parcel) => {
        console.log("Added parcel: ", result.toString())
        toast(parcelAddSuccessToast)
        props.deleteCallback(result.tracking_number)
        props.refreshParcelsCallback()
    }

    const addParcel = useAddParcel(onParcelAddSuccess, onParcelAddFailure)

    const addPackage = () => {
        const courier = props.parcel.courier_code || courierResult
        if (courier) {
            addParcel.call({
                tracking_number: props.parcel.tracking_number,
                courier_code: courier,
                label: name
            })
        } else console.warn("can't add parcel with null courier_code")
    }

    const onCourierAvatarClick = () => {
        setShowPopover(false)
        props.onSelectCourier(props.parcel)

    }

    const getCourierComponent = (parcel: Parcel, dcr: UseDetectCourierResult, result?: CourierCode) => {
        if (parcel.courier_code || result || dcr.error)
            return <CourierAvatar courier={parcel.courier_code || result} onClick={onCourierAvatarClick}/>
        else
            return <Skeleton w={"75px"} h={"24px"} borderRadius={'24px'}/>
    }

    return (

        <Flex
            w="full"  my={4} px={size === "desktop" ? 0 : 4}
            grow={1} direction={"row"} justify={"space-between"} align={"center"} flexWrap={"wrap"}>
            <Popover isOpen={showPopover} isLazy onClose={() => setShowPopover(false)}
                placement={"top-start"}>
                <Center>
                    <PopoverTrigger>
                        <div>
                            {getCourierComponent(props.parcel, detectCourier, courierResult)}
                        </div>
                    </PopoverTrigger>
                    <Stack ml={4} grow={1} w={'full'}>
                        <FormControl>
                            <FormLabel>Label</FormLabel>
                            <Input px={3} size={'sm'} placeholder={props.initialName} value={name} onChange={e => setName(e.target.value)}/>
                        </FormControl>
                        <TrackingLink courier={courierResult} trackingNumber={props.parcel.tracking_number} />
                    </Stack>
                </Center>
                <PopoverContent>
                    <PopoverArrow/>
                    <PopoverCloseButton/>
                    <PopoverHeader fontWeight={600}>Unable to detect courier</PopoverHeader>
                    <PopoverBody>Click here to manually choose a courier</PopoverBody>
                </PopoverContent>
            </Popover>
            <Flex align={"center"} justify={"flex-end"} grow={1} my={2} w={isDesktop ? 'auto' : 'full'}>
                <IconButton
                    aria-label={"cancel"}
                    mr={2}
                    isFullWidth={!isDesktop}
                    variant={"outline"}
                    icon={<CloseIcon/>}
                    onClick={() => props.deleteCallback(props.parcel.tracking_number)}/>
                <IconButton
                    aria-label={"save"}
                    ml={2}
                    isFullWidth={!isDesktop}
                    icon={<CheckIcon/>}
                    disabled={!courierResult && !props.parcel.courier_code}
                    colorScheme={"green"}
                    onClick={addPackage}
                />
            </Flex>

        </Flex>
    )
}

const detectCourierFailureToast: UseToastOptions = {
    title: "Warning",
    description: "Unable to detect courier",
    status: "warning",
    duration: 5000,
    isClosable: true,
}

const parcelAddFailureToast: UseToastOptions = {
    title: "Error",
    description: "Unable to add parcel",
    status: "error",
    duration: 5000,
    isClosable: true,
}

const parcelAddSuccessToast: UseToastOptions = {
    title: "Success!",
    description: "Added parcel to trackit!",
    status: "success",
    duration: 5000,
    isClosable: true,
}