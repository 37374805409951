import React, {useEffect, useState} from 'react'
import {MenuItem} from "./TrackitHeader";
import {useLocation} from "react-router-dom";
import {
    Avatar,
    Flex,
    Heading,
    IconButton,
    UnorderedList,
    useColorMode,
    useColorModeValue,
    Text,
    Button, HStack
} from '@chakra-ui/react';
import {CloseIcon, MoonIcon, SunIcon} from '@chakra-ui/icons';
import {Link as RouterLink} from 'react-router-dom'
import firebase from 'firebase'

interface MobileMenuProps {
    showing: boolean
    items: MenuItem[]
    width?: number
    closeCallback: () => void
    user?: firebase.User | null
    signOutCallback: () => void
}

const DEFAULT_WIDTH = 300


interface MobileMenuItemProps {
    item: MenuItem
    selected: boolean
    closeCallback: () => void
}

const MobileMenuItem = (props: MobileMenuItemProps): JSX.Element => {
    return (
        <Flex w={"full"} mx={2} my={4} onClick={props.closeCallback}>
            {props.selected ?
                <Heading as={RouterLink} to={props.item.link} color={"green.500"}>{props.item.name}</Heading>
                :
                <Heading as={RouterLink} to={props.item.link}>{props.item.name}</Heading>
            }
        </Flex>
    )
}

interface UserRowProps {
    user: firebase.User
    signOutCallback: () => void
}
const UserRow = (props: UserRowProps): JSX.Element => {
    return (
        <Flex w={'full'} align={"center"} direction={"row"} justify={"space-between"}>
            <HStack>
                <Avatar src={props.user.photoURL || undefined} name={props.user.displayName || undefined} size={'md'} />
                <Text ml={3} fontSize={'lg'}>{props.user.displayName}</Text>
            </HStack>
            <Button variant={"ghost"} colorScheme={'red.400'} onClick={props.signOutCallback}>Sign out</Button>
        </Flex>
    )
}

export default function MobileMenu(props: MobileMenuProps): JSX.Element {
    const location = useLocation()
    const [selected, setSelected] = useState("/")
    const bg = useColorModeValue("gray.50", "gray.700")
    const modeIcon = useColorModeValue(<MoonIcon/>, <SunIcon/>)
    const { toggleColorMode } = useColorMode()

    useEffect(() => {
        setSelected(location.pathname !== "/" ? location.pathname : "/dashboard")
    }, [location])

    return (
        <Flex
            bg={bg}
            position={"absolute"}
            left={0}
            top={0}
            bottom={0}
            direction={"column"}
            zIndex={100}
            width={`${props.width || DEFAULT_WIDTH}px`}
            ml={props.showing ? 0 : -(props.width || DEFAULT_WIDTH)}
            transition={"margin-left 200ms ease-in-out"}
            boxShadow={props.showing ? "-7px 0px 21px 7px #1A202C" : 'none'}
        >
            <Flex w={"full"} align={"center"} justify={"flex-end"}>
                <IconButton
                    mx={2}
                    my={4}
                    aria-label={"close-menu"}
                    onClick={props.closeCallback}
                    icon={<CloseIcon/>}
                    variant={"ghost"}
                />
            </Flex>

            <UnorderedList >
                {props.items.map((item: MenuItem) =>
                    <MobileMenuItem
                        key={`mobile-menu-${item.name}`}
                        item={item}
                        selected={item.link === selected}
                        closeCallback={props.closeCallback}
                    />
                )}
                <IconButton aria-label={"theme toggle"} onClick={toggleColorMode} icon={modeIcon} variant={"ghost"}/>
            </UnorderedList>

            <Flex direction={'column'} grow={1} justify={'flex-end'} ml={'1em'} mb={'1em'}>
                {props.user && <UserRow user={props.user} signOutCallback={props.signOutCallback}/> }
            </Flex>
        </Flex>
    )
}