import React, {useCallback, useEffect, useState} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import Dashboard from "./components/dashboard/Dashboard";
import {
    Box,
    Button,
    Center, Flex, IconButton,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, useColorModeValue,
    useDisclosure,
    useTheme
} from '@chakra-ui/react';
import TrackitHeader, {MenuItem} from "./components/common/organisms/TrackitHeader";
import MobileMenu from "./components/common/organisms/MobileMenu";
import useIsDesktop from "./hooks/helpers/useIsDesktop";
import useAuth from "./hooks/helpers/useAuth";
import firebase from "firebase/app";
import firebaseui from "firebaseui";
import {CloseIcon} from "@chakra-ui/icons";
import axios from "axios";


const pages: MenuItem[] = [
    {
        name: "Dashboard",
        link: "/dashboard"
    },
    {
        name: "Help",
        link: "/help"
    }
]

interface AppProps {
    auth?: firebase.auth.Auth,
    authUI: firebaseui.auth.AuthUI
}

export const UserContext = React.createContext<firebase.User | null>(null)

const App = (props: AppProps): JSX.Element => {
    const isDesktop = useIsDesktop()
    const [showMenu, setShowMenu] = useState(false)
    const [user, setUser] = useState<firebase.User | null>(props.auth?.currentUser || null)
    const [authenticated, setAuthenticated] = useState(false)
    const [login, setLogin] = useState(false)
    const {onOpen, onClose, isOpen} = useDisclosure();

    const onSignInSuccess = (result: firebase.User) => {
        console.log("sign in success", result)
        setLogin(false)
        return true
    }

    const onSignInFailure = (error: unknown) => {
        console.log("sign in error", error)
    }

    const showLogoutDialog = () => {
        setShowMenu(false)
        onOpen()
    }

    const logOut = useCallback(() => {
        onClose()
        props.auth?.signOut().then(() => {
            console.log("signed out")
            window.location.reload()

        }).catch(console.warn)

    }, [props.auth])

    const auth = useAuth(onSignInSuccess, onSignInFailure)

    useEffect(() => props.auth?.onAuthStateChanged(setUser), [])


    //Set latest user token in cookies
    useEffect(() => {
        if (!user) {
            console.log("not signed in")
            setAuthenticated(false)
        } else {
            user.getIdToken(true).then(token => {
                console.log("logged in, setting headers")
                axios.defaults.headers = {Authorization: token}
                setAuthenticated(true)
            }).catch(console.warn)
        }
    }, [user])



    const onLogin = () => {
        setLogin(true)
        auth.signIn(props.authUI)
    }

    return (
        <UserContext.Provider value={user}>
            <Router>
                <Box h={"full"} w={"full"}>
                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                        <ModalOverlay/>
                        <ModalContent m={4}>
                            <ModalHeader>Sign Out?</ModalHeader>
                            <ModalCloseButton/>
                            <ModalBody>Are you sure you want to sign out?</ModalBody>
                            <ModalFooter>
                                <Button variant={"ghost"} mr={3} onClick={onClose}>Cancel</Button>
                                <Button colorScheme={"red"} mr={3} onClick={logOut}>Sign Out</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    {!isDesktop &&
                    <MobileMenu
                        showing={showMenu}
                        items={pages}
                        closeCallback={() => setShowMenu(false)}
                        user={user}
                        signOutCallback={showLogoutDialog}
                    />
                    }

                    <TrackitHeader
                        items={pages}
                        showMenuCallback={() => setShowMenu(true)}
                        loginCallback={onLogin}
                        logoutCallback={showLogoutDialog}
                    />
                    <Switch>
                        <Route path={"/dashboard"}>
                            <Dashboard loginCallback={onLogin} user={user} auth={authenticated}/>
                        </Route>
                        <Route path={"/help"}>
                            <div>Help</div>
                        </Route>
                        <Route path={"/"}>
                            <Dashboard loginCallback={onLogin} user={user} auth={authenticated}/>
                        </Route>
                    </Switch>
                </Box>
                <LoginComponent showing={login} setShowing={setLogin}/>
            </Router>
        </UserContext.Provider>
    );
}

interface LoginProps {
    showing: boolean
    setShowing: (showing: boolean) => void
}

const LoginComponent = (props: LoginProps): JSX.Element => {
    const theme = useTheme()
    const [visible, setVisible] = useState(props.showing)
    const [opacity, setOpacity] = useState(props.showing ? 1 : 0)
    const bg = useColorModeValue("white", "gray.700")

    useEffect(() => {
        if (props.showing) {
            setVisible(true)
            setTimeout(() => setOpacity(1), 20)
        } else {
            setOpacity(0)
            setTimeout(() => setVisible(false), 200)
        }
    }, [props.showing])

    return (
        <Center
            position={'absolute'}
            top={0} left={0} right={0} bottom={0}
            w={'full'}
            h={'full'}
            visibility={visible ? 'visible' : 'hidden'}
            display={visible ? 'flex' : 'none'}
            opacity={opacity}
            transition={'opacity 200ms ease-in-out'}
            bg={'rgba(0,0,0,.5)'}
            onClick={() => props.setShowing(false)}
        >
            <Flex w={{base: '100%', sm: "75%", md: '50%', xl: "33%"}} direction={'column'} bg={bg}
                  borderRadius={theme.radii.lg} p={4} m={4}>
                <Flex w={'full'} justify={'flex-end'}>
                    <IconButton
                        aria-label={"close login dialog"}
                        variant={'ghost'}
                        icon={<CloseIcon/>}
                        onClick={() => props.setShowing(false)}
                        float={'right'}
                    />
                </Flex>
                <Box id={"auth-container"}/>
            </Flex>

        </Center>
    )
}

export default App;
