import React, {ReactNode, useState} from "react";
import CourierAvatar from "../../../common/atoms/CourierAvatar";
import StatusIndicator from "../../../common/molecules/StatusIndicator";
import {
    Box,
    Divider,
    Input,
    Text,
    Flex,
    IconButton,
    ListItem,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    useBreakpointValue,
    useColorModeValue,
    InputGroup,
    InputRightElement,
    useToast,
    Spinner,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody, ModalContent, ModalCloseButton
} from "@chakra-ui/react";
import {CheckIcon, CloseIcon} from '@chakra-ui/icons'
import {MenuIcon} from '../../../../icons/TrackitIcons'
import {Parcel} from "../../../../../functions/src/model/domain/Trackit";
import TrackingLink from "../../../common/atoms/TrackingLink";
import {AxiosError} from "axios";
import useRenameParcel from "../../../../hooks/api/useRenameParcel";
import {renameParcelFailToast, renameParcelSuccessToast} from "../../../../utils/ToastUtils";
import TrackingInfo from "../../../common/molecules/TrackingInfo";


function DesktopListItem(props: ResponsiveParcelListItemProps): JSX.Element {
    //const hoverColor = useColorModeValue("rgba(0,0,0, .15)", "rgba(255,255,255, .05)")
    return (
        <Stack direction={'column'} width={"full"}>
            <Flex direction={"row"} pt={6} pb={2} justify={"space-between"} borderRadius={4}>
                <Flex direction={"row"} align={"center"} flexBasis={"50%"}>
                    <CourierAvatar courier={props.parcel.courier_code}/>
                    <Stack ml={4}>
                        {props.label}
                        <Box fontSize={"18px"} maxW={"15ch"} minW={"15ch"} isTruncated ms={4}>
                            <TrackingLink trackingNumber={props.parcel.tracking_number}
                                          courier={props.parcel.courier_code}/>
                        </Box>
                    </Stack>
                </Flex>
                <Flex align={"center"} justify={"center"} flexBasis={"50%"} >
                    <StatusIndicator
                        stage={props.parcel.tracking_data?.delivery_status || 'notfound'}
                        courier={props.parcel.courier_code}
                        fluid
                    />
                    {props.menu}
                </Flex>
            </Flex>
            <Accordion allowToggle>
                <AccordionItem borderBottom={'none'}>
                        <AccordionButton>
                            <AccordionIcon/>
                        </AccordionButton>
                    <AccordionPanel>
                        <TrackingInfo info={props.parcel.tracking_data?.origin_info?.trackinfo}/>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>
    )
}

function MobileListItem(props: ResponsiveParcelListItemProps): JSX.Element {
    return (
        <>
            <Flex direction={"row"} py={6} px={4} align={"center"} w={"full"}>
                <Flex direction={"column"} align={"center"} grow={1} w={"full"} overflowX={"hidden"}>
                    <Flex w={"full"} justify={"space-between"} align={"center"}>
                        <Stack direction={"row"} align={"center"}>
                            <CourierAvatar courier={props.parcel.courier_code}/>
                            {props.label}
                        </Stack>
                        {!props.isEditing &&
                            <Box fontSize={"18px"} maxW={"15ch"} isTruncated mx={2} textAlign={"end"}>
                                <TrackingLink trackingNumber={props.parcel.tracking_number}
                                              courier={props.parcel.courier_code} mini/>
                            </Box>
                        }
                    </Flex>
                    <Flex w={"full"} justify={"center"} pt={4}>
                        <StatusIndicator
                            stage={props.parcel.tracking_data?.delivery_status || 'notfound'}
                            courier={props.parcel.courier_code}
                            fluid
                        />
                    </Flex>
                </Flex>
                {props.menu}
            </Flex>
            {props.divider && <Divider/>}
        </>
    )
}

interface ParcelListItemProps {
    parcel: Parcel
    divider?: boolean
    onDeleteParcel: (parcel: Parcel) => void
}

interface ResponsiveParcelListItemProps extends ParcelListItemProps {
    link?: string
    menu: ReactNode
    label: ReactNode
    isEditing: boolean
}


export default function ParcelListItem(props: ParcelListItemProps): JSX.Element {
    const toast = useToast()
    const menuButtonColor = useColorModeValue("gray.700", "purple.300")
    const accentColor = useColorModeValue("green.400", "purple.300")
    const [label, setLabel] = useState<string>(props.parcel.label || "Label")
    const [rename, setRename] = useState<string>(label)
    const [editMode, setEditMode] = useState(false)
    const size: "mobile" | "desktop" | undefined = useBreakpointValue({base: "mobile", lg: "desktop"})
    const {isOpen, onOpen, onClose} = useDisclosure()


    const onParcelRename = () => {
        toast(renameParcelSuccessToast)
        setEditMode(false)
        setLabel(rename || "")
    }

    const onParcelRenameFail = (e: AxiosError) => {
        console.log(e)
        toast(renameParcelFailToast)
        if(props.parcel.label)
            setLabel(props.parcel.label)
    }

    const renameParcel = useRenameParcel(onParcelRename, onParcelRenameFail)

    const onSave = () => renameParcel.call(props.parcel.tracking_number, rename)

    const onDelete = () => props.onDeleteParcel(props.parcel)

    const listItemMenu = (isEditing: boolean, isMobile = false) => {
        return (
            <Box mr={-2}>
                { isEditing ?
                    <IconButton icon={<CloseIcon/>} onClick={() => setEditMode(false)}
                                aria-label={"cancel-edit"} /> :
                    <Menu>
                        <MenuButton as={IconButton} aria-label={"menu"} color={menuButtonColor}
                                    icon={<MenuIcon />} variant={"ghost"}/>
                        <MenuList>
                            <MenuItem onClick={() => setEditMode(true)}>Edit</MenuItem>
                            {isMobile && <MenuItem onClick={onOpen}>Info</MenuItem>}
                            <MenuDivider/>
                            <MenuItem onClick={onDelete} color={"red.400"}>Delete</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </Box>
        )
    }

    const editableLabel = (isEditing: boolean) => (
        <Stack direction={"row"}>
            {isEditing ?
                <InputGroup size="sm" >
                    <Input value={rename} onChange={e => setRename(e.target.value)} size={"sm"} focusBorderColor={accentColor}/>
                    <InputRightElement>
                        {renameParcel.loading ?
                            <Spinner color={accentColor} size={"sm"}/> :
                            <IconButton aria-label={"save"}
                                        size="sm"
                                        bg={accentColor}
                                        onClick={onSave}
                                        icon={<CheckIcon/>}
                                        disabled={rename === label}
                            />
                        }
                    </InputRightElement>
                </InputGroup>
                :
                <Text>{label}</Text>
            }

        </Stack>
    )

    const responsiveProps: ResponsiveParcelListItemProps = {
        parcel: props.parcel,
        divider: props.divider,
        isEditing: editMode,
        menu: listItemMenu(editMode, size !== 'desktop'),
        label: editableLabel(editMode),
        onDeleteParcel: () => undefined,
    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered >
                <ModalOverlay/>
                <ModalContent overflow={"hidden"}>
                    <ModalHeader>Detailed Information</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody overflowY={"auto"} >
                        <TrackingInfo info={props.parcel?.tracking_data?.origin_info?.trackinfo} maxHeight={400}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ListItem >
                {size === "desktop" ? <DesktopListItem {...responsiveProps}/> : <MobileListItem {...responsiveProps}/>}
            </ListItem>
        </>

    )

}

