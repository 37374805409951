import React from "react";
import {courierColors} from "../../../utils/ColorUtils";
import {CourierCode} from "../../../../functions/src/model/domain/Trackingmore";
import {Flex, Text, Tooltip, useColorModeValue} from "@chakra-ui/react";

interface CourierAvatarProps {
    courier?: CourierCode
    size?: number
    onClick?: () => void
}

const D_SIZE = 24;

export default function CourierAvatar(props: CourierAvatarProps): JSX.Element {
    const defaultColor = useColorModeValue("green.500", 'purple.400')
    const color = props.courier ? (courierColors.get(props.courier) || defaultColor) : defaultColor
    const size = props.size || D_SIZE

    return (
        <Flex minW={'75px'} w={"75px"} maxW={"75px"} h={`${size}px`} maxH={`${size}px`}
              justify={"center"} align={"center"} borderRadius={size}
              background={color} color={'white'} fontSize={12}
              paddingX={2}
              userSelect={'none'} cursor={props.onClick ? "pointer" : 'default'}
              fontWeight={'bolder'} onClick={props.onClick}>
            <Tooltip label={props.courier} aria-label={props.courier} placement={"top"} openDelay={500}>
                <Text isTruncated>{props.courier?.toUpperCase() || "unknown"}</Text>
            </Tooltip>
        </Flex>
    )

}
