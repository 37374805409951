import * as React from 'react'
import {LinkProps, Link, Text, useColorModeValue, useBreakpointValue, IconButton} from "@chakra-ui/react";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {trackingResources} from "../../../utils/CourierUtils";
import {CourierCode} from "../../../../functions/src/model/domain/Trackingmore";

interface TrackingLinkProps extends LinkProps {
    trackingNumber: string
    courier?: CourierCode
    size?: "sm" | "md" | "lg"
    mini?: boolean
}

const SM = "12"
const MD = "16"
const LG = "18"

export default function TrackingLink(props: TrackingLinkProps): JSX.Element {
    const linkColor = useColorModeValue("green.500", "purple.300")
    const [trackingLink, setTrackingLink] = useState<string>("")
    const fontSize = props.size === "sm" ? SM : props.size === "lg" ? LG : MD
    const maxWidth = useBreakpointValue({base: "20ch", md: "25ch", lg: "30ch"})

    useEffect(() => {
        if(props.courier) {
            const result = trackingResources.get(props.courier)
            if(result) {
                setTrackingLink(result.linkTemplate(props.trackingNumber))
            }
        }
    }, [props.courier])


    return trackingLink ?
        <Link href={trackingLink} target={"_blank"} referrerPolicy={"no-referrer"} color={linkColor} isTruncated
              maxWidth={maxWidth} fontSize={fontSize}>
            {props.mini?
                <IconButton href={trackingLink} icon={<ExternalLinkIcon/>} aria-label={"external-tracking-site"}
                            target={"_blank"} referrerPolicy={"no-referrer"} size={"sm"} variant={"ghost"}/>
                : props.trackingNumber
            }
        </Link>
        :
        <Text isTruncated maxWidth={maxWidth} fontSize={fontSize}>
            {props.trackingNumber}
        </Text>

}