import React, {useCallback, useEffect, useRef, useState} from 'react'

import {ParcelList} from "./components/molecules/ParcelList";
import {useUserParcels} from "../../hooks/api/useUserParcels";

import {
    Button,
    Center,
    Fade,
    Flex,
    Heading,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader,
    ModalOverlay,
    Spinner,
    useToast,
    useColorModeValue,
    useDisclosure,
    VStack
} from '@chakra-ui/react'
import NoParcels from "./components/molecules/NoParcels";
import TrackingInput from "./components/molecules/TrackingInput";
import {Parcel} from "../../../functions/src/model/domain/Trackit";
import CourierSelectDrawer from "./components/organisms/CourierSelectDrawer";
import {CourierCode} from "../../../functions/src/model/domain/Trackingmore";
import useDeleteParcel from "../../hooks/api/useDeleteParcel";
import {deleteParcelFailToast, deleteParcelSuccessToast} from "../../utils/ToastUtils";
import {AxiosError} from "axios";
import useCleanUpParcels from "../../hooks/api/useCleanUpParcels";
import OnboardParcelList from "./components/molecules/OnboardParcelList";
import firebase from "firebase";


interface DashboardProps {
    auth: boolean,
    loginCallback: () => void
    user: firebase.User | null
}

export default function Dashboard(props: DashboardProps): JSX.Element {
    const accentColor = useColorModeValue("green.500", "purple.300")
    const toast = useToast()
    const [parcels, setParcels] = useState<Parcel[]>([])
    const cleanUpParcels = useCleanUpParcels()
    const [onboardParcels, setOnboardParcels] = useState<Parcel[]>([])
    const [editParcel, setEditParcel] = useState<Parcel | undefined>(undefined)
    const courierSelect = useDisclosure()
    const deleteParcelRef = useRef<Parcel | undefined>()
    const deleteDialog = useDisclosure()

    const onParcelsFetched = (parcels: Parcel[]) => {
        setParcels(parcels)
        if(parcels.length > 0)
            cleanUpParcels.call()
    }

    const useParcels = useUserParcels(onParcelsFetched, console.warn)


    const onParcelDeleted = (number: string) => {
        console.log(`deleted ${number}`)
        toast(deleteParcelSuccessToast)
        useParcels.call()
    }
    const onParcelDeleteFail = (e: AxiosError) => {
        console.warn(`couldn't delete parcel`, e)
        toast(deleteParcelFailToast)
    }
    const deleteParcel = useDeleteParcel(onParcelDeleted, onParcelDeleteFail)

    const [trackedNumbers, setTrackedNumbers] = useState<string[]>([])

    useEffect(() => {  props.auth && useParcels.call() }, [props.auth])

    useEffect(() => {
        const trackedNums = parcels.map(p => p.tracking_number).concat(onboardParcels.map(ob => ob.tracking_number))
        setTrackedNumbers(trackedNums)
    }, [parcels, onboardParcels])

    const onDeleteOnboardParcel = (number: string) => {
        const obParcels = onboardParcels.filter(p => p.tracking_number !== number)
        setOnboardParcels(obParcels)
        console.log("delete onboard", number)
    }

    const trackitCallback = useCallback((number?: string) => {
         if(number) {
            const obParcels = Array.from(onboardParcels)
            obParcels.push({tracking_number: number})
            setOnboardParcels(obParcels)
        }
    }, [props.user, props.loginCallback])

    const courierSelectCallback = (parcel: Parcel) => {
        setEditParcel(parcel)
        courierSelect.onOpen()
    }

    const onEditCourierComplete = (courier?: CourierCode) => {
        if(!courier || !editParcel) return
        courierSelect.onClose()
        const newParcels = Array.from(onboardParcels)
        const idx = newParcels.findIndex(p => p.tracking_number === editParcel?.tracking_number)
        if(idx !== -1){
            const newParcel = Object.assign({}, editParcel, {courier_code: courier})
            newParcels.splice(idx, 1, newParcel)
            setOnboardParcels(newParcels)
        }
    }

    const onEditCourierCancel = () => {
        setEditParcel(undefined)
        courierSelect.onClose()
    }

    const onParcelDelete = (parcel: Parcel) => {
        deleteParcelRef.current = parcel
        deleteDialog.onOpen()
    }

    const handleDeleteParcel = () => {
        if(deleteParcelRef.current) {
            const {courier_code, tracking_number} = deleteParcelRef.current
            if(courier_code && tracking_number) {
                deleteParcel.call(courier_code, tracking_number)
                deleteDialog.onClose()
                deleteParcelRef.current = undefined
            }
        }
    }


    const noParcels = parcels.length === 0 && onboardParcels.length === 0

    const spinner = (showing: boolean) => (
        <Fade in={showing} unmountOnExit>
            <Center position={"absolute"} w={"full"} h={"full"} top={0} left={0}>
                <Spinner size={"xl"} color={accentColor}/>
            </Center>
        </Fade>
    );

    return (
        <VStack w={"full"} h={"full"} >
            <CourierSelectDrawer
                editParcel={editParcel}
                onSave={onEditCourierComplete}
                onCancel={onEditCourierCancel}
                isOpen={courierSelect.isOpen}
                onOpen={courierSelect.onOpen}
            />
            <Modal isOpen={deleteDialog.isOpen} onClose={deleteDialog.onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Delete Parcel?</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>{"This can\'t be undone"}</ModalBody>
                    <ModalFooter>
                        <Button mr={3} variant={"outline"} onClick={deleteDialog.onClose}>Cancel</Button>
                        <Button colorScheme={"red"} onClick={handleDeleteParcel}>Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex direction={"column"} w={"full"} transition={"min-height 200ms linear"}
                align={"center"}>
                <Heading size={"lg"} align={"center"} pt={8} px={2}>Track all your deliveries in one place</Heading>
                <TrackingInput onAdd={trackitCallback}
                               addedNumbers={trackedNumbers}
                               loginCallback={props.loginCallback}/>

                <OnboardParcelList obParcels={onboardParcels}
                                   onDeleteOnboardParcel={onDeleteOnboardParcel}
                                   refreshCallback={useParcels.call}
                                   courierSelectCallback={courierSelectCallback}/>
            </Flex>
            <Flex w={"full"} h={"full"} justify={"center"} overflowY={'auto'} position={"relative"}>
                {spinner(useParcels.loading)}
                <NoParcels showing={noParcels && !useParcels.loading}/>
                <ParcelList parcels={parcels} onParcelDelete={onParcelDelete}/>
            </Flex>
        </VStack>
    )
}


