import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";
import {TMResponse, TrackingmoreCourier} from "../../../functions/src/model/domain/Trackingmore";

export interface UseDetectCourierResult {
    call: (number :string) => void
    result?: TMResponse<TrackingmoreCourier[]>,
    loading?: boolean,
    error?: AxiosError
}


const detectCourierRequest = (number: string) : AxiosRequestConfig => ({
    url: "/api/tracking/detect",
    //url: "/api/mock/detect",
    method: "GET",
    params: { number }
})

export const useDetectCourier = (onResult: (r: TMResponse<TrackingmoreCourier[]>) => void, onError: (e: AxiosError) => void): UseDetectCourierResult => {
    const {call, result, loading, error} = useApi<TMResponse<TrackingmoreCourier[]>>(onResult, onError)
    return {
        call: (number: string) => call(detectCourierRequest(number)),
        result,
        loading,
        error
    }
}