import React from "react";
import {ReactComponent as Step} from '../../../assets/svg/step.svg'
import {ReactComponent as StepOutline} from '../../../assets/svg/step_outline.svg'
import {Flex, Tooltip, useColorModeValue} from '@chakra-ui/react'
import {CourierCode, DeliveryStatus} from "../../../model/domain/Trackingmore";

export interface StatusIndicatorProps {
    stage: DeliveryStatus
    courier: CourierCode | undefined
    fluid?: boolean
}

const fluidSizes = {
    width: "25%",
    maxWidth: "25%",
    minWidth: "25%",
    maxHeight: 25
}

const staticSizes = {
    width: 70,
    maxWidth: 70,
    height: 35,
    maxHeight: 35
}

const toolTips = [
    "Pending",
    "In Transit",
    "Out for Delivery",
    "Delivered"
]

const primarySet = ['pendding', 'transit', 'pickup', 'delivered']

export default function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
    const gray = useColorModeValue("#E2E8F0", "#4A5568")
    const red = useColorModeValue("#E53E3E", "#FC8181")
    const accentColor = useColorModeValue("#38A169", "#B794F4")

    const getToolTip = (stepIdx: number, stage: DeliveryStatus) => {
        if(primarySet.includes(stage))
            return toolTips[stepIdx]
        else if(stage === 'notfound')
            return 'Not Found'
        else if(stage === 'expired')
            return 'Expired'
        else if(stage === 'undelivered')
            return 'Undelivered'
        else return 'Exception'
    }

    const getStepColor = (stepIdx: number, stage: DeliveryStatus) => {
        if(primarySet.includes(stage)) {
            return stepIdx > primarySet.indexOf(stage) ? gray : accentColor
        } else if (stage === 'expired') {
            return accentColor
        }else {
            return stage === 'exception' ? red : gray
        }
    }

    return (
        <Flex direction={"row"} align={"center"} wrap={"nowrap"} maxHeight={"35px"} w={"full"} px={2}>
            {[0, 1, 2, 3].map(step => {
                const stepColor = getStepColor(step, props.stage)
                const toolTip = getToolTip(step, props.stage)
                const StepImage = ['expired', 'undelivered', 'exception'].includes(props.stage) ? StepOutline : Step
                return (
                    <Tooltip key={step} label={toolTip} aria-label={toolTip}
                             hasArrow bg={stepColor} color={'white'} placement={"top"}>
                        <StepImage
                            opacity={props.stage === 'notfound' ? .5 : 1}
                            style={props.fluid ? fluidSizes : staticSizes}
                            aria-label={`tracking-step-${step}`}
                            color={stepColor}
                    />
                </Tooltip>
                )
            })}
        </Flex>
    )

}
