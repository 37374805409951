import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, ColorModeScript} from "@chakra-ui/react";

import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css'

import theme from "./theme";
import {CookiesProvider} from "react-cookie";

const cfg = JSON.parse(process.env.FIREBASE_CONFIG as string)
firebase.initializeApp(cfg)
const auth = firebase.auth()
const functions = firebase.functions()

if(process.env.NODE_ENV === "development") {
    console.log("DEV - using auth & functions emulator")
    auth.useEmulator("http://localhost:9099")
    functions.useEmulator("localhost", 5001)
}

const authUi = new firebaseui.auth.AuthUI(auth)
console.info("firebase initialized")

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
            <ChakraProvider theme={theme}>
                <App auth={auth} authUI={authUi}/>
            </ChakraProvider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
