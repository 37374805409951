import {AxiosError, AxiosRequestConfig} from "axios";
import useApi from "./useApi";
import {CourierCode} from "../../model/domain/Trackingmore";


interface UseDeleteParcelResult {
    call: (courier: CourierCode, number: string) => void,
    loading: boolean,
    result?: string,
    error?: AxiosError
}

const useDeleteParcelRequest = (courier: CourierCode, number: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `api/parcels/delete/${courier}/${number}`
})

export default function useDeleteParcel(onResult?: (r: string) => void, onError?: (e: AxiosError) => void): UseDeleteParcelResult {
    const {call, result, loading, error} = useApi<string>(onResult, onError)

    return {
        call: (c: CourierCode, n: string) => call(useDeleteParcelRequest(c, n)),
        result,
        loading,
        error
    }

}