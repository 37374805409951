import {
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay, Text, Input, InputGroup, InputRightElement,
    List,
    Spinner
} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import {CourierCode, TMResponse, TrackingmoreCourier} from "../../../../../functions/src/model/domain/Trackingmore";
import {useGetCouriers} from "../../../../hooks/api/useGetCouriers";
import CourierListItem from "../atoms/CourierListItem";
import {Parcel} from "../../../../../functions/src/model/domain/Trackit";


interface CourierSelectDrawerProps {
    editParcel?: Parcel
    isOpen: boolean,
    onOpen: () => void,
    onSave: (courier?: CourierCode) => void
    onCancel: () => void
}


export default function CourierSelectDrawer(props: CourierSelectDrawerProps): JSX.Element {
    const [selectedCourier, setSelectedCourier] = useState<TrackingmoreCourier>()
    const [couriers, setCouriers] = useState<TrackingmoreCourier[]>([])
    const [filteredResults, setFilteredResults] = useState(couriers)
    const [filterText, setFilterText] = useState<string>("")

    const getCouriers = useGetCouriers((r: TMResponse<TrackingmoreCourier[]>) => {
        if (r.code === 200) setCouriers(r.data)
        else console.log("no couriers returned")
    })

    useEffect(getCouriers.call, [])

    //initialize selected item to passed parcel courier if possible
    useEffect(() => {
        if (couriers && props.editParcel?.courier_code) {
            const initial = couriers.find(c => c.courier_code === props.editParcel?.courier_code)
            if (initial)
                setSelectedCourier(initial)
        }
    }, [couriers, props.editParcel])

    useEffect(() => {
        if (couriers) {
            if (!filterText) setFilteredResults(couriers)
            else {
                const filtered = couriers.filter(tmc =>
                    (tmc.courier_code.includes(filterText) || tmc.courier_name.includes(filterText))
                )
                setFilteredResults(filtered)
            }
        }
    }, [couriers, filterText])

    const onCourierClick = (courier: TrackingmoreCourier) => {
        setSelectedCourier(courier.courier_code !== selectedCourier?.courier_code ? courier : undefined)
    }

    const onCancel = () => {
        setSelectedCourier(undefined)
        props.onCancel()
    }

    const onSave = () => {
        props.onSave(selectedCourier?.courier_code)
        setSelectedCourier(undefined)
    }

    const clearFilter = () => setFilterText("")

    return (
        <Drawer
            isOpen={props.isOpen}
            //isOpen={true}
            placement="right"
            onClose={onCancel}
        >
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader>Select a Courier</DrawerHeader>

                <DrawerBody overflow={"hidden"} h={"full"} position={"relative"}>
                    <InputGroup size={"md"}>
                        <Input placeholder="filter couriers" value={filterText}
                               pr="4.5rem" onChange={e => setFilterText(e.target.value)}/>
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={clearFilter} disabled={!filterText}>
                                clear
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Center position={"absolute"} w={"full"} h={"calc(100% - 50px)"}
                            top={"50px"} left={0} visibility={getCouriers.loading ? "inherit" : "hidden"}>
                        <Spinner/>
                    </Center>
                    {filteredResults && filteredResults.length > 0 ?
                        <List spacing={2} overflowY={"auto"} h={"calc(100% - 50px)"} mt={"24px"}>
                            {filteredResults.map((courier: TrackingmoreCourier, idx: number) =>
                                <CourierListItem
                                    key={`${courier.courier_code}-${idx}`}
                                    courier={courier}
                                    selected={courier.courier_code === selectedCourier?.courier_code}
                                    onClick={() => onCourierClick(courier)}
                                />)}
                        </List> :
                        <Center w={"full"} my={4}>
                            <Text color={"green.500"}>
                                No Results
                            </Text>
                        </Center>
                    }

                </DrawerBody>

                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button colorScheme="blue"
                            disabled={!selectedCourier}
                            onClick={onSave}>Save</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}