import {Box} from "@chakra-ui/react";
import {Parcel} from "../../../../../functions/src/model/domain/Trackit";
import OnboardParcelItem from "./OnboardParcelItem";
import React from "react";

interface OnboardParcelListProps {
    obParcels: Parcel[]
    onDeleteOnboardParcel: (number: string) => void,
    refreshCallback: () => void,
    courierSelectCallback: (parcel: Parcel) => void
}

export default function OnboardParcelList(props: OnboardParcelListProps): JSX.Element {

    return (
        <Box w={{base: "100%", sm: "75%", md: "66%", lg: "50%", xl: "50%", "2xl": "33%"}}>
            {props.obParcels.map((obp: Parcel) =>
                <OnboardParcelItem
                    key={obp.tracking_number}
                    parcel={obp}
                    initialName={`Package ${props.obParcels.length + 1}`}
                    deleteCallback={props.onDeleteOnboardParcel}
                    refreshParcelsCallback={props.refreshCallback}
                    onSelectCourier={props.courierSelectCallback}
                />
            )}
        </Box>
    )
}